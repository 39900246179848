export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    action: 'manage',
    resource: 'membership',
  },
  {
    title: 'Audio Visuals/Dramatic Works',
    icon: 'FilmIcon',
    route: 'member-audio-visuals',
    action: 'manage',
    resource: 'membership',
  },
  {
    title: 'Music Rights',
    icon: 'PlayIcon',
    route: 'member-musical-works',
    action: 'manage',
    resource: 'membership',
  },
]
