<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications_count"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications_count }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media v-if="notification.type === 'App\\Notifications\\IncompleteProfile'">
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.notification }}
            </span>
          </p>
          <b-badge pill variant="light-secondary" size="sm" @click="viewNotifications(notification)">Mark As Read</b-badge>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-if="notifications.length"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="markAllNotificationsAsRead"
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  data() {
    return {
      notifications_count: 0,
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  directives: {
    Ripple,
  },
  async mounted() {
    await this.getNotifications()
    setInterval(async () => {
      await this.getNotifications()
    }, 30000)
  },
  methods: {
    getNotifications() {
      if (localStorage.getItem('accessToken')) {
        this.$http.get('/membership/notifications')
          .then(response => {
            this.notifications_count = response.data.notifications.length
            this.notifications = response.data.notifications
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An Error Occurred while fetching notifications',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    markAllNotificationsAsRead() {
      this.$http.get('/membership/notifications/all/read')
        .then(() => {
          this.notifications = []
          this.notifications_count = 0
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An Error Occurred',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    viewNotifications(notification) {
      if (notification.type === 'App\\Notifications\\IncompleteProfile') {
        this.$http.get(`/membership/notification/${notification.id}/read`)
          .then(response => {
            this.notifications = response.data.notifications
            this.notifications_count = response.data.notifications.length
            // Check if user already completed profile
            this.$http.get('/membership/member')
              .then(res => {
                if (!res.data.data.kin.length || !res.data.data.payment_information) {
                  this.$router.replace({ name: notification.data.link })
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Complete Profile',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification Marked As Read',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                    },
                  })
                }
              })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An Error Occurred',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.$http.get(`/membership/notification/${notification.id}/read`)
          .then(response => {
            this.notifications = response.data.notifications
            this.notifications_count = response.data.notifications.length
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification Marked As Read',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An Error Occurred',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style>

</style>
